// Breakpoints
$xsMax: 575px;
$smMin: 576px;
$smMax: 767px;
$mdMin: 768px;
$mdMax: 991px;
$lgMin: 992px;
$lgMax: 1199px;
$xlgMin: 1200px;

// Colors
/*
$mainColor: #4696e5;
$lightHoverColor: #eeeeee;
$lightGrey: #dddddd;
$dangerColor: #dc3545;
$mediumGrey: #dee2e6;
*/

$mainColor: #FFC038;
$lightHoverColor: #eeeeee;
$lightGrey: #dddddd;
$dangerColor: #dc3545;
$mediumGrey: #dee2e6;

// Misc
$headerHeight: 57px;
$footer-height: 2.3rem;
$footer-margin: .8rem;
