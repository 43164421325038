@import './utils/base';

.invisible {
  display: none;
}

.app-container {
  height: 100%;
}

.app {
  padding-top: $headerHeight;
  height: 100%;
}

.shlink-wrapper {
  min-height: 100%;
  padding-bottom: $footer-height + $footer-margin;
  margin-bottom: -($footer-height + $footer-margin);
}

.shlink-footer {
  height: $footer-height;
  margin-top: $footer-margin;
  padding: 0;

  @media (min-width: $mdMin) {
    padding: 0 15px;
  }
}
